import React, { useEffect, useState } from "react";

import { Flex, NewGrid as Grid, Button } from "Atoms";
import { GET_VALID_DISCOUNTS } from "GraphQLQueries";
import {
    CartIdAndQuantity,
    CombinedDiscount,
    DISCOUNT_TYPE,
    MenuCategoryProducts,
    OrderWindow,
    Discount,
    CartProduct,
    OPEN_PRODUCT_TYPE
} from "Types";
import { DiscountUtils } from "./DiscountPriceModal";
import { DiscountButton } from "./DiscountButton";
import { DiscountProductList, Placement } from "./DiscountProductList";
import { useValidDiscounts } from "./hook/useValidDiscounts";
import { useMothershipQuery } from "Hooks";
import { useOrderWindowsStore, useQoplaStore } from "Stores";

type Props = {
    discountUtils: DiscountUtils;
    companyId: string;
    activeOrderWindow: OrderWindow;
    isDisplayed: boolean;
    turnOffComboDiscount: boolean;
};

type DiscountQuery = {
    getValidDiscounts: Discount[];
};

export type CartProductDiscounts = {
    cartProductId: string;
    hasComboDiscount: boolean;
    discountIds: string[];
};

export const DiscountBody: React.FC<Props> = ({
    discountUtils,
    companyId,
    activeOrderWindow,
    isDisplayed,
    turnOffComboDiscount
}) => {
    const { setActiveDiscountWithQuantities } = discountUtils;
    const { selectedShop } = useQoplaStore();
    const { alcoholProductIds } = useOrderWindowsStore();

    const [productsSelectedForDiscount, setProductsSelectedDiscount] = useState<Map<string, boolean>>(new Map());
    const [discountedProductQuantities, setDiscountedProductQuantities] = useState<Map<string, number>>(new Map());
    const [hasDiscountApplied, onSetHasDiscountApplied] = useState<boolean>(false);
    const [potentialProductDiscounts, setPotentialProductDiscounts] = useState<CartProductDiscounts[]>();
    const [discountState, setDiscountState] = useState<Discount | null>(activeOrderWindow.discount);
    const { data, loading } = useMothershipQuery<DiscountQuery>(GET_VALID_DISCOUNTS, {
        variables: { companyId },
        fetchPolicy: "network-only"
    });

    const { validDiscounts, hasCheckedExpanded } = useValidDiscounts(
        data?.getValidDiscounts,
        loading,
        selectedShop?.id
    );

    const onSetPotentialProductDiscounts = (cartProductId: string, discountId: string) => {
        let potentialCartProduct = potentialProductDiscounts?.find(
            (product: CartProductDiscounts) => product.cartProductId === cartProductId
        );
        if (potentialCartProduct) {
            const discountIds = [...potentialCartProduct.discountIds, discountId];
            const newPotentialDiscount =
                potentialProductDiscounts?.filter((product: any) => product.cartProductId !== cartProductId) ?? [];
            potentialCartProduct.discountIds = [...new Set(discountIds)];
            setPotentialProductDiscounts([...newPotentialDiscount, potentialCartProduct]);
        }
    };

    useEffect(() => {
        if (!isDisplayed) {
            toggleSelectAll(false);
        }
    }, [isDisplayed]);

    useEffect(() => {
        if (activeOrderWindow.discountedProductIds) {
            const initialMap = new Map();
            activeOrderWindow.discountedProductIds.forEach(id => initialMap.set(id, true));
            onSetHasDiscountApplied(!!activeOrderWindow.discountedProductIds.length);
            setProductsSelectedDiscount(initialMap);
        }
        if (activeOrderWindow?.cartProducts) {
            const potentialCartProductDiscount: CartProductDiscounts[] = activeOrderWindow?.cartProducts?.map(
                (cart: CartProduct) => {
                    return {
                        cartProductId: cart.id,
                        hasComboDiscount:
                            cart.orderProduct.combinedDiscounts?.some(
                                (combo: CombinedDiscount) => combo.discountType === DISCOUNT_TYPE.COMBO_DISCOUNT
                            ) ?? false,
                        discountIds: []
                    };
                }
            );
            setPotentialProductDiscounts(potentialCartProductDiscount);

            const cartQuantities: Map<string, number> = activeOrderWindow?.cartProducts?.reduce(
                (totalMap: Map<string, number>, cart: CartProduct) => {
                    const { orderProduct } = cart;
                    if (orderProduct.combinedDiscounts && discountState) {
                        const getDiscountQuantity =
                            orderProduct?.combinedDiscounts?.find(
                                (combined: CombinedDiscount) => combined.discountId === discountState.id
                            )?.quantityUsedForDiscount ?? orderProduct.quantity;
                        totalMap.set(cart.id, getDiscountQuantity);
                    } else {
                        totalMap.set(cart.id, orderProduct.quantity);
                    }
                    return totalMap;
                },
                new Map()
            );

            setDiscountedProductQuantities(cartQuantities);
        }
    }, []);

    useEffect(() => {
        if (discountState) {
            setDiscount(discountState);
        }
        const isAllUnchecked = Array.from(productsSelectedForDiscount).every(([_, check]) => !check);
        if (isAllUnchecked && productsSelectedForDiscount.size > 0) {
            setDiscount(null);
        }
    }, [productsSelectedForDiscount]);

    const disableDiscountButtons = Array.from(productsSelectedForDiscount).filter(([_, value]) => value).length === 0;

    const updateProductsSelected = (id: string) => {
        const checked = productsSelectedForDiscount && productsSelectedForDiscount.get(id) === true;
        const selectedProducts = productsSelectedForDiscount.set(id, !checked);
        const isAllUnchecked = Array.from(productsSelectedForDiscount).every(([_, check]) => !check);
        setProductsSelectedDiscount(new Map(selectedProducts));
        if (isAllUnchecked) {
            setDiscount(null);
        }
    };

    const toggleSelectAll = (checked: boolean) => {
        if (productsSelectedForDiscount) {
            const updatedMap = new Map();
            activeOrderWindow?.cartProducts?.forEach(({ id, updatedUnitPrice, orderProduct }) => {
                /** Get ref product id */
                const refId = orderProduct?.refProductId || orderProduct?.refBundleProductId!;
                /** Check open product */
                const isOpenProduct = refId.includes(OPEN_PRODUCT_TYPE.OPEN_PRODUCT);
                /** If not alcohol product or an open product check discounts etc.. */
                if (!alcoholProductIds.includes(refId) && !isOpenProduct) {
                    if (!updatedUnitPrice) {
                        updatedMap.set(id, checked);
                    }
                    const discountsAreAvailable = potentialProductDiscounts?.some(
                        (cart: CartProductDiscounts) =>
                            cart.cartProductId === id && !!cart.discountIds.length && !cart.hasComboDiscount
                    );
                    if (!discountsAreAvailable) {
                        updatedMap.set(id, false);
                    }
                } else {
                    updatedMap.set(id, false);
                }
            });
            setProductsSelectedDiscount(updatedMap);
        }
    };

    const productCanBeDiscounted = (productId: string, discountId: string) => {
        return (
            potentialProductDiscounts?.some(
                (cart: CartProductDiscounts) =>
                    cart.cartProductId === productId && cart.discountIds.includes(discountId)
            ) ?? false
        );
    };

    const setCartIdsAndQuantities = (discountedProductIds: string[]) => {
        return (
            discountedProductIds.map((id: string) => {
                const currentQuantityValue = discountedProductQuantities.get(id)!;
                const orderQuantity = activeOrderWindow?.cartProducts?.find((cart: CartProduct) => cart.id === id);
                return {
                    cartId: id,
                    quantity: currentQuantityValue,
                    discountType:
                        orderQuantity?.orderProduct.quantity === currentQuantityValue
                            ? DISCOUNT_TYPE.APPLIED_DISCOUNT
                            : DISCOUNT_TYPE.QUANTITY_APPLIED_DISCOUNT
                };
            }) ?? []
        );
    };

    const setDiscount = (discount: Discount | null) => {
        if (!discount) {
            setActiveDiscountWithQuantities(null, [], null);
        } else {
            const productIdsSelected = Array.from(productsSelectedForDiscount)
                .filter(([_, value]) => value)
                .map(([id]) => id);
            let areProductIdsDiscountable: Array<string> = [];
            if (!hasDiscountApplied) {
                areProductIdsDiscountable = productIdsSelected.filter((id: string) => {
                    if (!productCanBeDiscounted(id, discount.id)) {
                        productsSelectedForDiscount.set(id, false);
                        return false;
                    }
                    return true;
                });
            } else {
                onSetHasDiscountApplied(false);
            }

            const discountedProductIds = areProductIdsDiscountable.length
                ? areProductIdsDiscountable
                : productIdsSelected;

            const cartIdsAndQuantities: CartIdAndQuantity[] = setCartIdsAndQuantities(discountedProductIds);
            setActiveDiscountWithQuantities(discount, discountedProductIds, cartIdsAndQuantities);
        }
        setDiscountState(discount);
    };

    useEffect(() => {
        if (discountState) {
            setDiscount(discountState);
        }
    }, [discountedProductQuantities]);

    const setDiscountedQuantityForCartId = (cartId: string, quantity: number) => {
        const cartIds = discountedProductQuantities;
        if (cartIds) {
            cartIds.set(cartId, quantity);
            setDiscountedProductQuantities(new Map(cartIds));
        }
    };

    useEffect(() => {
        if (validDiscounts && validDiscounts?.length > 0 && potentialProductDiscounts?.length && hasCheckedExpanded) {
            const { cartProducts } = activeOrderWindow;
            cartProducts.forEach((cartProduct: CartProduct) => {
                const {
                    orderProduct: { menuCategoryId },
                    menuProduct,
                    menuBundleProduct
                } = cartProduct;
                validDiscounts.forEach((discount: Discount) => {
                    if (!!discount.menuCategoryAndProductIds?.length) {
                        const selectedProduct = menuProduct || menuBundleProduct;
                        const containsDiscount = discount.menuCategoryAndProductIds.some(
                            (product: MenuCategoryProducts) =>
                                product.categoryIds.includes(menuCategoryId) ||
                                product.productIds.includes(selectedProduct?.id ?? "")
                        );
                        if (containsDiscount) {
                            onSetPotentialProductDiscounts(cartProduct.id, discount.id);
                        }
                    } else {
                        onSetPotentialProductDiscounts(cartProduct.id, discount.id);
                    }
                });
            });
        }
    }, [validDiscounts?.length, activeOrderWindow?.cartProducts?.length, hasCheckedExpanded]);

    const renderDiscounts = () => {
        if (disableDiscountButtons) {
            return validDiscounts?.map((discount: Discount, index: number) => {
                const { id: idToUse } = discount;
                const noProductsOnThisDiscount =
                    potentialProductDiscounts?.every(
                        (cartProduct: CartProductDiscounts) => !cartProduct.discountIds.includes(idToUse)
                    ) ?? false;

                return (
                    <DiscountButton
                        disabled={disableDiscountButtons}
                        noProductsOnThisDiscount={noProductsOnThisDiscount}
                        key={discount.id + "" + index}
                        discount={discount}
                        setDiscount={setDiscount}
                        selectedDiscount={discountState}
                    />
                );
            });
        }
        return validDiscounts?.map((discount: Discount, index: number) => {
            const { id: idToUse } = discount;
            const selectedProductIds =
                potentialProductDiscounts?.some((cartProduct: CartProductDiscounts) => {
                    const product = productsSelectedForDiscount.get(cartProduct.cartProductId);
                    if (product) {
                        return cartProduct.discountIds.includes(idToUse);
                    }
                    return false;
                }) ?? false;
            const noProductsOnThisDiscount =
                potentialProductDiscounts?.every(
                    (cartProduct: CartProductDiscounts) => !cartProduct.discountIds.includes(idToUse)
                ) ?? false;

            return (
                <DiscountButton
                    disabled={!selectedProductIds}
                    noProductsOnThisDiscount={noProductsOnThisDiscount}
                    key={discount.id + "" + index}
                    discount={discount}
                    setDiscount={setDiscount}
                    selectedDiscount={discountState}
                />
            );
        });
    };

    const displayDiscounts = renderDiscounts();

    return (
        <Grid height="500px" p={4} gap={6} templateColumns={{ xs: "1fr", md: "1fr 1fr" }}>
            <Flex direction="column" overflow="auto">
                <Flex justifyContent="flex-start" mb={3} height="35px">
                    <Button variant="outline" themeColor="blackAlpha" onClick={() => toggleSelectAll(true)}>
                        Markera alla
                    </Button>
                    <Button
                        variant="outline"
                        themeColor="blackAlpha"
                        onClick={() => toggleSelectAll(false)}
                        isDisabled={productsSelectedForDiscount.size === 0}
                        ml={2}
                    >
                        Avmarkera alla
                    </Button>
                </Flex>
                <DiscountProductList
                    activeOrderWindow={activeOrderWindow}
                    productsToDiscount={productsSelectedForDiscount}
                    selectedDiscount={discountState}
                    validCartProductDiscounts={potentialProductDiscounts}
                    updateProductsToDiscount={updateProductsSelected}
                    placement={Placement.DISCOUNT}
                    quantitiesToDiscount={discountedProductQuantities}
                    setCartQuantityToBeDiscounted={setDiscountedQuantityForCartId}
                    turnOffComboDiscount={turnOffComboDiscount}
                />
            </Flex>
            <Grid
                overflow="auto"
                height={displayDiscounts && displayDiscounts.length <= 6 ? "min-content" : "auto"}
                templateColumns="repeat(auto-fill, minmax(150px, 1fr))"
                gap={6}
            >
                {displayDiscounts}
            </Grid>
        </Grid>
    );
};

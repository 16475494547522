import React, { useState } from "react";

import { Modal, ModalActions, ModalBody, ModalCloseBtn, ModalHeader } from "Molecules";
import { Flex, Header, Button } from "Atoms";

import { DiscountBody } from "./DiscountBody";
import { PriceAdjustBody } from "./PriceAdjustBody";
import {
    CartIdAndQuantity,
    CartProduct,
    CombinedDiscount,
    Discount,
    DISCOUNT_STATE,
    DISCOUNT_TYPE,
    OrderWindow
} from "Types";
import { useDiscounts } from "Providers";
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "Organisms";
import { useOrderWindowsStore } from "Stores";

type Props = {
    companyId: string;
    closeModal: () => void;
    discountUtils: DiscountUtils;
    activeOrderWindow: OrderWindow;
};

export type DiscountUtils = {
    getActiveOrderWindowDiscount: () => {
        discount: Discount;
        discountedProductIds: string[];
    };
    setActiveOrderWindowDiscount: (discount: Discount | null, productIdsToDiscount?: string[]) => void;
    setActiveDiscountWithQuantities: (
        discount: Discount | null,
        productIdsToDiscount?: string[],
        discountedCartIdsAndQuantities?: CartIdAndQuantity[] | null
    ) => void;
    splitOrderWindowCartProducts: () => void;
    updateUnitPrice: (updatedUnitPrice: number, productIds: string[]) => void;
    resetUnitPrice: (id: string) => void;
};

export const DiscountPriceModal: React.FC<Props> = ({ companyId, closeModal, discountUtils }) => {
    const { getActiveOrderWindow } = useOrderWindowsStore();
    const activeOrderWindow = getActiveOrderWindow();
    const hasAdjustedPrice = activeOrderWindow?.cartProducts?.some(cp => cp.updatedUnitPrice);
    const {
        switchCombinedDiscountState: { comboDiscountState, setComboDiscountState, hasPausedDiscounts }
    } = useDiscounts();
    const [activeTabIndex, setActiveTabIndex] = useState<number>(hasAdjustedPrice ? 1 : 0);

    const [comboDiscountIsOff, setcomboDiscountIsOff] = useState<boolean>(() => {
        if (comboDiscountState === DISCOUNT_STATE.NONE || comboDiscountState === DISCOUNT_STATE.ON) {
            return false;
        }
        return true;
    });

    const hasComboDiscounts =
        activeOrderWindow?.cartProducts?.some((cart: CartProduct) => {
            const { orderProduct } = cart;
            return orderProduct.combinedDiscounts?.some(
                (combo: CombinedDiscount) => combo.discountType === DISCOUNT_TYPE.COMBO_DISCOUNT
            );
        }) || hasPausedDiscounts;

    const onCloseModal = () => {
        discountUtils.splitOrderWindowCartProducts();
        closeModal();
    };

    const onSelectComboDiscountState = () => {
        setComboDiscountState(comboDiscountIsOff ? DISCOUNT_STATE.ON : DISCOUNT_STATE.OFF);
        setcomboDiscountIsOff(prev => !prev);
    };

    const comboDiscountButtonColour = comboDiscountIsOff ? "green" : "red";
    const comboDiscountButtonText = comboDiscountIsOff ? "Aktivera" : "Avaktivera";

    if (!activeOrderWindow) {
        return <></>;
    }

    return (
        <Modal size="xxl" open>
            <ModalCloseBtn
                onClick={onCloseModal}
                size="lg"
                backgroundColor="red.500"
                color="white"
                borderRadius="50%"
                _hover={undefined}
                mt={2}
                mr={2}
            />
            <ModalHeader>
                <Header fontSize="4xl">Rabatter & Pris</Header>
            </ModalHeader>
            <ModalBody isScrolling={true} p={0} style={{ padding: "0", minHeight: "500px" }}>
                <Tabs isLazy index={activeTabIndex} onChange={setActiveTabIndex}>
                    <TabList ml={4} mr={4}>
                        <Tab fontSize="xl">Rabatter</Tab>
                        <Tab fontSize="xl">Justera pris</Tab>
                    </TabList>

                    <TabPanels pt={4}>
                        <TabPanel>
                            <DiscountBody
                                companyId={companyId}
                                discountUtils={discountUtils}
                                activeOrderWindow={activeOrderWindow}
                                isDisplayed={activeTabIndex === 0}
                                turnOffComboDiscount={comboDiscountIsOff}
                            />
                        </TabPanel>
                        <TabPanel>
                            <PriceAdjustBody
                                activeOrderWindow={activeOrderWindow}
                                discountUtils={discountUtils}
                                isDisplayed={activeTabIndex === 1}
                            />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </ModalBody>
            <ModalActions backgroundColor="gray.100">
                <Flex justifyContent="flex-end" alignItems="center">
                    {hasComboDiscounts && (
                        <Button
                            marginRight="auto"
                            themeColor={comboDiscountButtonColour}
                            onClick={onSelectComboDiscountState}
                            size="md"
                            minWidth="auto"
                            fontWeight="normal"
                        >
                            {`${comboDiscountButtonText} Komborabatter`}
                        </Button>
                    )}
                    <Button disabled={false} onClick={onCloseModal} themeColor="green" size="xl">
                        Klar
                    </Button>
                </Flex>
            </ModalActions>
        </Modal>
    );
};

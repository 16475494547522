import { createContext, useContext } from "react";

import { PreferredPuckTab, StringOrNumber } from "Types";
import { PosQueriesVariables } from "../utils";

type ResponseData = {
    data: {
        getCompanyRefProducts: any;
        getIngredientsGroup: any;
        getMenusByIds: any;
        getPairedPos: any;
        getShopOverride: any;
        getShopSettings: any;
        searchTerminals: any;
    };
    errors: any;
    loading: boolean;
};

interface IInternPosContext {
    pingResponse: {
        shopChangeNumber: StringOrNumber;
        companyChangeNumber: StringOrNumber;
        mothershipAlive: boolean;
        controlUnitAlive: boolean;
    };
    settings: {
        preferredPuckTab: PreferredPuckTab;
    };
    posQueries: {
        response: {
            data: {
                getCompanyRefProducts: any;
                getIngredientsGroup: any;
                getMenusByIds: any;
                getPairedPos: any;
                getShopOverride: any;
                getShopSettings: any;
                searchTerminals: any;
            };
            errors: any;
            loading: boolean;
            variables: PosQueriesVariables;
        };
        load: (variables: PosQueriesVariables) => Promise<ResponseData>;
    };
}

export const InternalPosContext = createContext<IInternPosContext>(null as any);

export const useInternalPos = () => {
    const ctx = useContext(InternalPosContext);
    if (!ctx) {
        throw new Error("useInternalPos must be within a InternalPosProvider");
    }
    return ctx;
};

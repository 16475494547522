import { useEffect, useState } from "react";
import { cloneDeep } from "lodash";

import { Discount, APPLIED_DISCOUNT_TYPE } from "Types";
import { createSimpleHashId } from "Providers";

type HookReturn = {
    validDiscounts: Discount[] | undefined;
    hasCheckedExpanded: boolean;
};

/**
 * Hook - check the discounts (Applied - amount discount) if they have per product / total
 * Clones the discount and adds a type to the discount  EACH_PRODUCT / WHO
 * @param discounts pass in loaded discounts from the db call
 * @param isLoading if the discounts are still loading
 * @param selectedShopId current shop is
 * @returns {HookReturn} valid Discounts and a check for any discounts needed to expand
 */
export const useValidDiscounts = (
    discounts: Discount[] | undefined,
    isLoading: boolean,
    selectedShopId: string | undefined
): HookReturn => {
    const [validDiscounts, setValidDiscounts] = useState<Discount[]>();
    const [hasExpandedDiscounts, setHasExpandedDiscounts] = useState<boolean>(false);

    const filterDiscounts = (discount: Discount) => {
        if (discount.shopIds.length > 0) {
            return discount.shopIds.includes(selectedShopId) && !discount.disabled;
        } else {
            return !discount.disabled;
        }
    };

    const cloneAndDuplicateAppliedDiscount = (discount: Discount) => {
        const { canApplyOnEachProduct, canApplyOnWholeOrder } = discount;
        if (discount.amount) {
            let expandedDiscount;
            if (canApplyOnEachProduct) {
                let eachProductDiscount = cloneDeep(discount);

                eachProductDiscount = {
                    ...eachProductDiscount,
                    appliedDiscountType: APPLIED_DISCOUNT_TYPE.PER_PRODUCT,
                    ...(canApplyOnWholeOrder && {
                        orgId: eachProductDiscount.id,
                        id: createSimpleHashId([discount.id, APPLIED_DISCOUNT_TYPE.PER_PRODUCT])
                    })
                };
                expandedDiscount = [eachProductDiscount];
            }

            if (canApplyOnWholeOrder) {
                let wholeOrderDiscount = cloneDeep(discount);
                wholeOrderDiscount = {
                    ...wholeOrderDiscount,
                    appliedDiscountType: APPLIED_DISCOUNT_TYPE.TOTAL,
                    ...(canApplyOnEachProduct && {
                        orgId: wholeOrderDiscount.id,
                        id: createSimpleHashId([discount.id, APPLIED_DISCOUNT_TYPE.TOTAL])
                    })
                };
                expandedDiscount =
                    expandedDiscount && expandedDiscount?.length
                        ? [...expandedDiscount, wholeOrderDiscount]
                        : [wholeOrderDiscount];
            }

            return expandedDiscount;
        }
        return null;
    };

    const expandValidDiscounts = () => {
        const expandedValidDiscounts =
            validDiscounts?.reduce((discounts: Discount[], value: Discount) => {
                const expandDiscount = cloneAndDuplicateAppliedDiscount(value);
                if (expandDiscount) {
                    discounts = [...discounts, ...expandDiscount];
                } else {
                    discounts = [...discounts, value];
                }
                return discounts;
            }, []) ?? [];
        setValidDiscounts(expandedValidDiscounts);
    };

    useEffect(() => {
        if (discounts && !isLoading) {
            const filteredDiscounts: Discount[] = discounts.filter(filterDiscounts);
            setValidDiscounts(filteredDiscounts);
        }
    }, [discounts, isLoading]);

    useEffect(() => {
        if (validDiscounts && validDiscounts.length && !hasExpandedDiscounts) {
            setHasExpandedDiscounts(true);
            expandValidDiscounts();
        }
    }, [validDiscounts]);

    return { validDiscounts: validDiscounts, hasCheckedExpanded: hasExpandedDiscounts };
};

import React, { useEffect, useState } from "react";
import moment from "moment";
import { useMutation } from "react-apollo";
import { IoMdRefresh } from "@react-icons/all-files/io/IoMdRefresh";
import { FcLock } from "@react-icons/all-files/fc/FcLock";

import { openDrawer } from "TempUtils";
import {
    usePos,
    useQopla,
    usePunchClock,
    useBaxiSocket,
    useLanguage,
    useLocalPosSettings,
    getPostponedOrderWindows,
    useSplitCashRegister,
    getExtendedOrderProducts
} from "Providers";
import { Flex, Label, Stack } from "Atoms";
import { modalNames, PaymentMethod, PosTypes, reportTypeConstants } from "Constants";
import { NewIconButton } from "Molecules";
import { ADD_CASH_CHANGE, UPDATE_OPENED_REGISTER_COUNT, CREATE_ZX_REPORT_FOR_SHOP } from "GraphQLMutations";
import { Clock } from "../../../../common/Clock";
import { EnhancedReceiptModal } from "../ReceiptModal";
import { ActivePhoneOrdersModal } from "..";
import { DiscountPriceModal } from "../DiscountModal/DiscountPriceModal";
import { printXReport } from "../../../../../utils/Printer";
import { PosCashPopup, PosFooterButton, PosMoreAndSettingsModal, ConnectionContainer } from "./components";
import {
    Settings,
    OrderWindow,
    SelectedPos,
    GOOGLE_ANALYTICS_EVENT_CATEGORY,
    GOOGLE_ANALYTICS_EVENT_ACTION
} from "Types";
import { OpenProductModal } from "../OpenProductModal/OpenProductModal";
import { gaProductionEvent, isCartFinished } from "Utils";
import { useOrderWindowsStore, useModalStore, useTableStore, usePosStore, orderWindowsStore } from "Stores";
import { useAwaitConfirmAccessCodeModal } from "../PosUnlockLockModal";

type Props = {
    activeOrderWindow: OrderWindow;
    posTerminals: any[];
    makeCloudRefund: (terminalId: string, amount: number, posOrderInput: object) => void;
    activePane: number;
    shopSettings: Settings;
    posZoomLevel: number;
    isOpenProductEnabled: boolean;
    disableDiscountButton: boolean;
    onEnterTableSelectionMode: () => void;
};

const initialModalState = {
    name: "",
    modalProps: null
};

const POPUPS = {
    CASH: "cash",
    MORE: "more"
};

export const PosFooter: React.FC<Props> = ({ activePane, shopSettings, posZoomLevel, onEnterTableSelectionMode }) => {
    const activeOrderWindow = orderWindowsStore(state => state.getActiveOrderWindow());
    const [receiptModalOpen, setReceiptModalOpen] = useState(false);
    const [modal, setModal] = useState(initialModalState);
    const [popup, setPopup] = useState("");

    const [createZXReportForShop] = useMutation(CREATE_ZX_REPORT_FOR_SHOP);

    const splitCashRegisterService = useSplitCashRegister();
    const { onOpenPunchClock } = usePunchClock();
    const {
        cancelCloudPurchase,
        resetTerminalStateById,
        makeCloudRefund,
        terminalMessages,
        terminalStates,
        connectionErrorMsgs: cloudConnectionErrorMsg,
        prevTerminalStates
    } = useBaxiSocket();
    const { translate } = useLanguage();

    const { discountUtils } = usePos();
    const { activePaymentTabs, queueHasStarted, posTerminals } = usePosStore();
    const { orderWindows, menus } = useOrderWindowsStore();
    const verifyAccessCode = useAwaitConfirmAccessCodeModal();

    const {
        posSettings: { enableOpenProduct, customerFacingOptions },
        setPosSettings: { onSetEnableCustomerFacing }
    } = useLocalPosSettings();
    const {
        selectedTable,
        isInTableSelectionMode,
        setIsInTableSelectionMode,
        hasShopTables,
        setHasShopTables,
        isTableOpen,
        setIsTableOpen,
        closeTable
    } = useTableStore();
    const tableService = {
        selectedTable,
        isInTableSelectionMode,
        setIsInTableSelectionMode,
        hasShopTables,
        setHasShopTables,
        isTableOpen,
        setIsTableOpen,
        closeTable
    };

    const { openModal: openModalProvider } = useModalStore();

    const {
        selectedShop: shop,
        selectedPos,
        selectedCompany,
        selectedTerminal,
        setSelectedPos,
        authenticatedUser
    } = useQopla();
    const allowedPaymentMethods = shop?.settings?.allowedPaymentMethods || [];
    const enablePunchClock = shop?.settings?.enablePunchClock || false;

    const receiptPrinter = selectedPos?.receiptPrinter;
    const allowPhonePostponeOrders = selectedPos?.allowPhonePostponeOrders;
    const postponePaymentEnabled = selectedPos?.postponePaymentEnabled;

    const [updateOpenedRegisterCount] = useMutation(UPDATE_OPENED_REGISTER_COUNT);
    const [addCashChange] = useMutation(ADD_CASH_CHANGE);

    const hasSelectedTable = !!tableService.selectedTable?.id;
    const hasActivePaymentTab = !!Object.keys(activePaymentTabs).length;
    const shouldShowTableButton =
        tableService.hasShopTables && !tableService.isInTableSelectionMode && !tableService.isTableOpen;
    const shouldShowTableCloseButton = tableService.hasShopTables && tableService.isTableOpen && hasSelectedTable;

    useEffect(() => {
        // check for ingoing cash change
        if (allowedPaymentMethods.includes(PaymentMethod.CASH)) {
            if (!selectedPos?.cashChangeInputs?.length) {
                onOpenIngoingCashChangeModal();
            }
        }
    }, [allowedPaymentMethods, selectedPos?.cashChangeInputs]);

    const onOpenModal = (name: string, modalProps: any) => {
        setModal({
            name,
            modalProps
        });
    };
    const onCloseModal = () => setModal(initialModalState);

    const onOpenPopup = (name: string) => setPopup(name);
    const onClosePopup = () => setPopup("");

    const onOpenAllergyModal = () => {
        onClosePopup();
        openModalProvider(modalNames.ALLERGY_MODAL, {
            menuProductCategories: menus[activePane].menuProductCategories,
            posType: PosTypes.POS
        });
    };

    const handleOpenDrawer = () => {
        onClosePopup();
        updateOpenedRegisterCount({
            variables: { posId: selectedPos?.id }
        });
        gaProductionEvent({
            category: GOOGLE_ANALYTICS_EVENT_CATEGORY.POS_FOOTER,
            action: GOOGLE_ANALYTICS_EVENT_ACTION.CLICK_BUTTON,
            label: "Open cash register"
        });
        openDrawer(receiptPrinter);
    };

    const handleCashChange = async (changeAmount: any) => {
        const changeInput = {
            dateTime: String(moment.utc()),
            amount: changeAmount
        };
        const { data } = await addCashChange({
            variables: {
                posId: selectedPos?.id,
                dateTimeAmount: changeInput
            }
        });
        if (data.addCashChange) {
            setSelectedPos({
                ...selectedPos,
                cashChangeInputs: selectedPos?.cashChangeInputs.concat([changeInput])
            } as SelectedPos);
        }
    };

    const handleCreateXReport = async () => {
        const { data } = await createZXReportForShop({
            variables: {
                shopId: shop!.id,
                reportType: reportTypeConstants.X,
                companyUserName: selectedCompany.contactInformation?.name
            }
        });

        if (data.createZXReportForShop) {
            const report = data.createZXReportForShop;
            const receiptPrinter = selectedPos?.receiptPrinter;
            printXReport(receiptPrinter, report);
        }
    };

    const onOpenIngoingCashChangeModal = () => {
        onClosePopup();
        const cashChangeInputs = (selectedPos || {}).cashChangeInputs || [];

        const persistedChange =
            //@ts-ignore
            cashChangeInputs.length > 0 ? cashChangeInputs.reduce((a, b) => a + b.amount, 0.0) : undefined;

        const modalContent = {
            persistedChange,
            shopId: shop!.id,
            selectedPos,
            handlePosCashChange: handleCashChange,
            openDrawer: handleOpenDrawer
        };

        gaProductionEvent({
            category: GOOGLE_ANALYTICS_EVENT_CATEGORY.POS_FOOTER,
            action: GOOGLE_ANALYTICS_EVENT_ACTION.OPEN_MODAL,
            label: "Open cash modal"
        });

        return openModalProvider(modalNames.INGOINGCASHCHANGEMODAL, modalContent);
    };

    const onOpenCodeCheckModal = async () => {
        onCloseModal();
        const accessGranted = await verifyAccessCode();

        if (accessGranted) {
            onSetEnableCustomerFacing(!customerFacingOptions.enableCustomerFacing);
        }
    };

    const postPonedOrderWindowsAmount = getPostponedOrderWindows(orderWindows).length;

    const showMoneyBtns = allowedPaymentMethods && allowedPaymentMethods.includes(PaymentMethod.CASH);
    const hasCloudConnectionErrors = Array.from(cloudConnectionErrorMsg.values()).some(msg => !!msg);
    const cloudErrorMessage = Array.from(cloudConnectionErrorMsg.values()).find(msg => !!msg);
    const isCustomerFacing = customerFacingOptions.allowCustomerFacing && customerFacingOptions.enableCustomerFacing;

    const isCartNotFinished = !isCartFinished(activeOrderWindow?.cartProducts);
    const isPostponedOrder = !!activeOrderWindow?.postponePayment;
    const hasNewCartProducts =
        activeOrderWindow && isPostponedOrder && getExtendedOrderProducts(activeOrderWindow).length > 0;

    const discountButtonIsDisbaled =
        hasNewCartProducts || tableService.isInTableSelectionMode || splitCashRegisterService.isInSplitMode;

    return (
        <>
            <Flex
                as="footer"
                bg="gray.800"
                align="center"
                justify="space-between"
                height="inherit"
                width="100%"
                px={4}
                zoom={`${posZoomLevel}%`}
            >
                <Flex align="center" flex="1">
                    {isCustomerFacing && (
                        <PosFooterButton mr={3} onClick={onOpenCodeCheckModal} leftIcon={FcLock}>
                            Kassan
                        </PosFooterButton>
                    )}
                    {!isCustomerFacing && (
                        <>
                            <PosFooterButton
                                mr={3}
                                onClick={() => setReceiptModalOpen(true)}
                                disabled={tableService.isInTableSelectionMode || splitCashRegisterService.isInSplitMode}
                            >
                                Kvitton
                            </PosFooterButton>
                            <PosFooterButton
                                mr={3}
                                disabled={discountButtonIsDisbaled}
                                onClick={() => {
                                    onOpenModal(modalNames.DISCOUNTPRICEMODAL, {
                                        companyId: selectedCompany.id,
                                        discountUtils
                                    });
                                }}
                            >
                                Rabatter & Pris
                            </PosFooterButton>
                            {allowPhonePostponeOrders && (
                                <PosFooterButton
                                    mr={3}
                                    disabled={
                                        hasSelectedTable ||
                                        tableService.isInTableSelectionMode ||
                                        splitCashRegisterService.isInSplitMode
                                    }
                                    onClick={() => {
                                        onOpenModal(modalNames.ACTIVE_PHONE_ORDERS_MODAL, {});
                                    }}
                                >
                                    <Label circular justifyContent="center" bg="gray.800" color="white" mr={2}>
                                        {postPonedOrderWindowsAmount}
                                    </Label>
                                    Parkerade
                                </PosFooterButton>
                            )}

                            {enableOpenProduct && (
                                <PosFooterButton
                                    mr={2}
                                    disabled={
                                        tableService.isInTableSelectionMode || splitCashRegisterService.isInSplitMode
                                    }
                                    onClick={() => {
                                        onOpenModal(modalNames.OPEN_PRODUCT_MODAL, {});
                                    }}
                                >
                                    Öppen
                                </PosFooterButton>
                            )}
                            <PosFooterButton
                                mr={3}
                                disabled={tableService.isInTableSelectionMode || splitCashRegisterService.isInSplitMode}
                                onClick={() => {
                                    gaProductionEvent({
                                        category: GOOGLE_ANALYTICS_EVENT_CATEGORY.POS_FOOTER,
                                        action: GOOGLE_ANALYTICS_EVENT_ACTION.OPEN_MODAL,
                                        label: "Open settings modal"
                                    });
                                    onOpenModal("moreAndSettingsModal", {
                                        onOpenCodeCheckModal,
                                        onOpenAllergyModal,
                                        handleCreateXReport,
                                        onOpenPunchClock,
                                        enablePunchClock,
                                        shopSettings,
                                        posTerminals,
                                        postponePaymentEnabled
                                    });
                                }}
                            >
                                Mer
                            </PosFooterButton>
                            {showMoneyBtns && (
                                <PosCashPopup
                                    onClosePopup={onClosePopup}
                                    onOpenPopup={() => {
                                        gaProductionEvent({
                                            category: GOOGLE_ANALYTICS_EVENT_CATEGORY.POS_FOOTER,
                                            action: GOOGLE_ANALYTICS_EVENT_ACTION.POPUP,
                                            label: "Cash popup"
                                        });
                                        onOpenPopup(POPUPS.CASH);
                                    }}
                                    disabled={
                                        tableService.isInTableSelectionMode || splitCashRegisterService.isInSplitMode
                                    }
                                    open={popup === POPUPS.CASH}
                                    onOpenIngoingCashChangeModal={onOpenIngoingCashChangeModal}
                                    handleOpenDrawer={handleOpenDrawer}
                                    posZoomLevel={posZoomLevel}
                                />
                            )}
                            {hasCloudConnectionErrors && (
                                <PosFooterButton
                                    ml={3}
                                    backgroundColor="red.600"
                                    _hover={{ backgroundColor: "red.500" }}
                                    color="white"
                                    leftIcon={IoMdRefresh}
                                    onClick={() => location.reload()}
                                >
                                    {cloudErrorMessage}
                                </PosFooterButton>
                            )}
                            <Stack h="auto" isInline stretch={3} mr={6}>
                                {shouldShowTableButton && (
                                    <PosFooterButton
                                        disabled={
                                            hasActivePaymentTab ||
                                            splitCashRegisterService.isInSplitMode ||
                                            queueHasStarted
                                        }
                                        onClick={onEnterTableSelectionMode}
                                    >
                                        {translate("table")}
                                    </PosFooterButton>
                                )}
                                {shouldShowTableCloseButton && (
                                    <PosFooterButton
                                        disabled={
                                            hasActivePaymentTab ||
                                            isCartNotFinished ||
                                            splitCashRegisterService.isInSplitMode
                                        }
                                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                            event.currentTarget.disabled = true;
                                            closeTable(tableService?.selectedTable);
                                        }}
                                    >
                                        {translate("closeTable")}
                                    </PosFooterButton>
                                )}
                            </Stack>
                        </>
                    )}
                </Flex>

                <Flex align="center" justify="space-evenly">
                    <NewIconButton
                        backgroundColor={"rgba(0,0,0,0)"}
                        color={"gray.600"}
                        fontSize="25px"
                        mr={2}
                        icon={IoMdRefresh}
                        onClick={() => {
                            gaProductionEvent({
                                category: GOOGLE_ANALYTICS_EVENT_CATEGORY.POS_FOOTER,
                                action: GOOGLE_ANALYTICS_EVENT_ACTION.CLICK_BUTTON,
                                label: "Refresh POS"
                            });
                            location.reload();
                        }}
                    />
                    <Clock mr={4} />
                    <ConnectionContainer
                        cloudCardTerminalConnected={
                            selectedTerminal?.cloudEnabled ? !hasCloudConnectionErrors : undefined
                        }
                    />
                </Flex>
                {modal.name === "moreAndSettingsModal" && (
                    // @ts-ignore
                    <PosMoreAndSettingsModal {...modal.modalProps} closeModal={onCloseModal} />
                )}

                {modal.name === modalNames.OPEN_PRODUCT_MODAL && (
                    <OpenProductModal
                        closeModal={onCloseModal}
                        standardVatRate={selectedCompany.standardProductVatRate}
                        vatRates={selectedCompany.vatRates ?? []}
                    />
                )}
                {modal.name === modalNames.ACTIVE_PHONE_ORDERS_MODAL && (
                    <ActivePhoneOrdersModal closeModal={onCloseModal} />
                )}
                {modal.name === modalNames.DISCOUNTPRICEMODAL && (
                    // @ts-ignore
                    <DiscountPriceModal {...modal.modalProps} closeModal={onCloseModal} />
                )}
                {receiptModalOpen && (
                    <EnhancedReceiptModal
                        open={receiptModalOpen}
                        modalContent={{
                            posTerminals: posTerminals,
                            shopId: shop?.id,
                            posId: selectedPos?.id,
                            receiptPrinter: selectedPos?.receiptPrinter,
                            terminalId: selectedTerminal?.terminalId,
                            makeCloudRefund,
                            cancelCloudPurchase,
                            resetTerminalStateById,
                            terminalMessages,
                            terminalStates,
                            prevTerminalStates
                        }}
                        closeModal={() => setReceiptModalOpen(false)}
                    />
                )}
            </Flex>
        </>
    );
};

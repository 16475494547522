import React, { useState } from "react";
import { FiTrash } from "@react-icons/all-files/fi/FiTrash";

import { SpringButton } from "TempUtils";
import { useTheme } from "Providers";
import { modalNames } from "Constants";
import { Button, Flex, Header, Textarea, Text } from "Atoms";
import { Modal, ModalHeader, ModalBody, ModalActions, ModalCloseBtn } from "Molecules";
import { useQoplaStore } from "Stores";

type Props = {
    modalContent: {
        currentComment: string;
        addCommentToOrderProduct: (comment: string, event?: any) => void;
        event?: any;
        isExpress?: boolean;
    };
    closeModal: (modal: string) => void;
};

export const AddCommentsModal: React.FC<Props> = ({
    modalContent: { currentComment, addCommentToOrderProduct, isExpress, event },
    closeModal
}) => {
    const { selectedShop } = useQoplaStore();
    const shop = selectedShop;
    const { colors } = useTheme();

    const defaultCommentValue = currentComment ? currentComment : "";

    const [comment, setComment] = useState(defaultCommentValue);

    const predefinedComments = shop?.settings?.predefinedComments || [];
    const hasPredefinedComments = predefinedComments && predefinedComments.length > 0;

    const addPredefinedComment = (text: string) => {
        const updatedComment = comment.includes(text) ? comment.replace(text + "\n", "") : comment.concat(text + "\n");
        setComment(updatedComment);
    };

    const predefinedCommentsButtons = predefinedComments?.map((predefinedComment: string, idx: number) => {
        return (
            <SpringButton
                key={idx}
                customOnClick={() => addPredefinedComment(predefinedComment)}
                style={{
                    height: "150px",
                    width: "150px",
                    marginLeft: "0.5rem",
                    backgroundColor: comment.includes(predefinedComment) ? colors.green["300"] : "#E2E8F0"
                }}
            >
                <Text fontSize={"xl"}>{predefinedComment}</Text>
            </SpringButton>
        );
    });

    return (
        <Modal open={true} closeOnDimmerClick={false} placement="center" size={"xl"}>
            <ModalHeader className="space-between" style={{ borderBottom: "none" }}>
                <Header as={"h1"} m={0}>
                    Lägg till kommentar
                </Header>
                <ModalCloseBtn
                    onClick={() => closeModal(modalNames.ADD_COMMENTS_MODAL)}
                    size="lg"
                    style={{ backgroundColor: colors.red["500"], color: colors.white, borderRadius: "50%" }}
                />
            </ModalHeader>
            <ModalBody>
                <Flex justifyContent={"space-between"}>
                    <Textarea
                        marginTop={2}
                        value={comment}
                        onChange={(e: any) => {
                            setComment(e.target.value);
                        }}
                        placeholder="Kommentar..."
                        minHeight={40}
                        minWidth="100%"
                        resize="none"
                    />
                </Flex>
                {hasPredefinedComments && !isExpress && (
                    <>
                        <Text fontSize={"1.5rem"} my={4}>
                            Fördefinerade kommentarer
                        </Text>
                        <Flex overflow="auto" maxHeight="400px" wrap="wrap" justifyContent={"flex-start"} gridGap={2}>
                            {predefinedCommentsButtons}
                        </Flex>
                    </>
                )}
            </ModalBody>
            <ModalActions>
                <Flex justifyContent="flex-end">
                    <Button
                        size={"lg"}
                        onClick={() => setComment("")}
                        isDisabled={!comment}
                        themeColor="red"
                        rightIcon={FiTrash}
                        mr={2}
                    >
                        Rensa
                    </Button>
                    <Button size={"lg"} themeColor="green" onClick={() => addCommentToOrderProduct(comment, event)}>
                        Spara
                    </Button>
                </Flex>
            </ModalActions>
        </Modal>
    );
};

import React, { useState } from "react";
import { BundleCreatorCategory } from "./BundleCreatorCategory";
import { get } from "lodash";

import { getBundleCategoryColor, scrollToCategory } from "../utils";
import { NewDivider } from "Atoms";

export const BundleCreator = ({ selectedBundleCartProduct }) => {
    const {
        menuBundleProduct: { refBundleProduct, bundleCategoryColors }
    } = selectedBundleCartProduct;
    const { bundleProductCategories } = refBundleProduct;

    const [uncompleteCategories, setUncompleteCategories] = useState([]);

    const scrollHandler = (categoryIsCompleted, bundleProductCategoryId) => {
        const idxOfClicked = bundleProductCategories.findIndex(cat => cat.id === bundleProductCategoryId);

        if (categoryIsCompleted) {
            const currUncompleteCategories = uncompleteCategories.filter(id => id !== bundleProductCategoryId);
            const bundleCategory = get(bundleProductCategories[idxOfClicked + 1], "id");

            const nextCategory = currUncompleteCategories.includes(bundleCategory)
                ? bundleCategory
                : currUncompleteCategories[0];

            scrollToCategory(nextCategory || bundleCategory);
            setUncompleteCategories(currUncompleteCategories);
        }
        if (!categoryIsCompleted) {
            if (!uncompleteCategories.includes(bundleProductCategoryId)) {
                setUncompleteCategories(prev => [...prev, bundleProductCategoryId]);
            }
        }
    };

    const bundleCategories = [...bundleProductCategories]
        .sort((a, b) => a.sortOrder - b.sortOrder)
        .map(cat => {
            const bundleCategoryColor = !!bundleCategoryColors.length
                ? getBundleCategoryColor(bundleCategoryColors, cat.id)
                : null;
            return (
                <BundleCreatorCategory
                    key={cat.id}
                    bundleProductCategory={cat}
                    bundleCategoryColor={bundleCategoryColor}
                    selectedBundleProduct={selectedBundleCartProduct}
                    scrollToNext={scrollHandler}
                    id={cat.id}
                />
            );
        });

    return (
        <div className="np-bundle-creator-container">
            <h1>{refBundleProduct.name}</h1>
            <NewDivider my={4} color="gray.400" />
            <div>{bundleCategories}</div>
        </div>
    );
};

import React, { useEffect } from "react";
import { countBy } from "lodash";
import propTypes from "prop-types";

import { BundleItemOperation, usePos, modals } from "Providers";
import { PosProduct } from "./";
import { getMenuBundleModificationsToUse } from "Utils";
import { getBundleModAddonPriceText } from "TempUtils/getPriceText";
import { getSingleModification } from "TempUtils/modsHelpers";
import { limitReached, preSelectBundleItems } from "../utils/paymentHelpers";
import { useModalStore } from "Stores";
import { NewGrid } from "Atoms";

export const BundleCreatorCategory = ({
    bundleProductCategory,
    bundleCategoryColor,
    selectedBundleProduct,
    scrollToNext,
    id
}) => {
    const {
        getRemainingStockQuantity,
        refProducts,
        addBundleItemToBundleProduct,
        hideFromStockIds,
        outOfStockIds,
        isRefProductOutOfStock
    } = usePos();
    const modalContext = useModalStore();

    const {
        menuBundleProduct,
        orderProduct: { selectedBundleProductItems }
    } = selectedBundleProduct;

    useEffect(() => {
        preSelectBundleItems(
            bundleProductCategory,
            menuBundleProduct,
            selectedBundleProductItems,
            refProducts,
            addBundleItemToBundleProduct,
            outOfStockIds,
            false
        );
    }, [selectedBundleProductItems]);

    const addRefProductWithSingleMod = (refProduct, singleModification, bundleItemOperation) => {
        addBundleItemToBundleProduct(
            refProduct,
            bundleProductCategory,
            singleModification,
            false,
            null,
            bundleItemOperation
        );
    };

    const getBundleItemOperation = (limitReached, refProduct, bCategory) => {
        const { limit, id } = bCategory;
        if (!limitReached) {
            return BundleItemOperation.ADD;
        } else {
            const bundleItemAmount = countBy(selectedBundleProductItems, req => {
                return req.name === refProduct.name && req.bundleProductCategoryId === id;
            });

            if (bundleItemAmount.true === limit) {
                return BundleItemOperation.RESET_CATEGORY;
            } else {
                return BundleItemOperation.RESET_BUNDLE_ITEM;
            }
        }
    };

    const addRefProduct = (refProduct, modificationsToUse, isOutOfStock) => {
        const catLimitReached = limitReached(bundleProductCategory, selectedBundleProductItems);
        const bundleItemOperation = getBundleItemOperation(catLimitReached, refProduct, bundleProductCategory);

        // If bundle item becomes out of stock after adding, user needs to be able to remove it.
        // Before we disabled bundle item if bundle item isOutOfStock. Making it not possible to remove
        // Instead we've moved logic here to enable user to remove out of stock bundle item
        const shouldCancelAdding = isOutOfStock && bundleItemOperation !== BundleItemOperation.RESET_CATEGORY;
        if (shouldCancelAdding) {
            return;
        }

        if (modificationsToUse) {
            const singleModification = getSingleModification(modificationsToUse);
            if (!!singleModification || catLimitReached) {
                addRefProductWithSingleMod(refProduct, singleModification, bundleItemOperation);
            } else {
                modalContext.openModal(modals.posModificationsModal, {
                    menuProduct: refProduct,
                    modificationsToUse,
                    isFromBundle: true,
                    modalCallback: selectedModifications => {
                        addBundleItemToBundleProduct(
                            refProduct,
                            bundleProductCategory,
                            selectedModifications,
                            false,
                            null,
                            bundleItemOperation
                        );
                    }
                });
            }
        } else {
            addBundleItemToBundleProduct(refProduct, bundleProductCategory, null, false, null, bundleItemOperation);
        }
    };

    const _refProducts = bundleProductCategory.refProductIdList
        .map(refProdId => refProducts[refProdId])
        .filter(refProd => {
            if (!refProd) {
                return false;
            } else return !(hideFromStockIds && hideFromStockIds.has(refProd.id));
        });
    const selectedProductsInCategory = selectedBundleProductItems.filter(
        bundleItem => bundleItem.bundleProductCategoryId === bundleProductCategory.id && !!bundleItem.refProductId
    ).length;
    const categoryIsCompleted = bundleProductCategory.limit === selectedProductsInCategory;

    useEffect(() => {
        scrollToNext(categoryIsCompleted, bundleProductCategory.id);
    }, [categoryIsCompleted]);

    const productCards = _refProducts.map(refProd => {
        const selectedQuantity = selectedBundleProductItems
            ? selectedBundleProductItems.filter(
                  bundleItem =>
                      bundleItem.refProductId === refProd.id &&
                      bundleProductCategory.id === bundleItem.bundleProductCategoryId
              ).length
            : false;
        const remainingStock = getRemainingStockQuantity(refProd.id);
        const isOutOfStock = isRefProductOutOfStock(refProd.id, outOfStockIds);
        const modificationsToUse = getMenuBundleModificationsToUse(refProd, menuBundleProduct);
        const priceText = modificationsToUse ? getBundleModAddonPriceText(modificationsToUse) : "";

        return (
            <PosProduct
                key={refProd.id}
                priceText={priceText}
                isDisabled={(categoryIsCompleted && selectedQuantity === 0) || isOutOfStock}
                remainingStock={remainingStock}
                selectedQuantity={selectedQuantity}
                customColor={bundleCategoryColor}
                product={refProd}
                isOutOfStock={isOutOfStock}
                productHandler={() => addRefProduct(refProd, modificationsToUse, isOutOfStock)}
            />
        );
    });

    return (
        <>
            <span className={categoryIsCompleted ? "np-bundle-cat-header-completed" : "np-bundle-cat-header"} id={id}>
                <h2>{bundleProductCategory.name}</h2>
                <h3>
                    {selectedProductsInCategory} / {bundleProductCategory.limit} st
                </h3>
            </span>
            <NewGrid gap={4} mt={4} templateColumns="repeat(auto-fill, 150px)" templateRows="repeat(auto-fill, 150px)">
                {productCards}
            </NewGrid>
        </>
    );
};

BundleCreatorCategory.propTypes = {
    bundleProductCategory: propTypes.object.isRequired,
    bundleCategoryColor: propTypes.string,
    selectedBundleProduct: propTypes.object
};

BundleCreatorCategory.defaultPropTypes = {
    bundleCategoryColor: "#FFF"
};

import { Flex } from "Atoms";
import React, { useEffect, useRef, useState } from "react";
import { Popup } from "semantic-ui-react";

import { PosFooterButton } from ".";

type Props = {
    onClosePopup: () => void;
    onOpenPopup: () => void;
    onOpenIngoingCashChangeModal: () => void;
    handleOpenDrawer: () => void;
    open: boolean;
    posZoomLevel: number;
    disabled: boolean;
};

export const PosCashPopup: React.FC<Props> = ({
    onClosePopup,
    onOpenPopup,
    onOpenIngoingCashChangeModal,
    handleOpenDrawer,
    open,
    posZoomLevel,
    disabled
}) => {
    const [popupPosition, setPopupPosition] = useState({ positionX: 0, positionY: 0 });

    const buttonRef = useRef<Element>(null);

    useEffect(() => {
        if (buttonRef.current) {
            const currentX = buttonRef.current.getBoundingClientRect().left - 70;
            const currentY = buttonRef.current.getBoundingClientRect().top - 76;
            if (popupPosition.positionX !== currentX && popupPosition.positionY !== currentY) {
                setPopupPosition({
                    positionX: currentX,
                    positionY: currentY
                });
            }
        }
    });

    return (
        <Popup
            trigger={
                <PosFooterButton mr={3} ref={buttonRef} disabled={disabled}>
                    Kontanthantering
                </PosFooterButton>
            }
            position="top center"
            on="click"
            flowing
            onClose={onClosePopup}
            onOpen={onOpenPopup}
            open={open}
            style={{
                backgroundColor: "#2D3748",
                transform: 0,
                top: `${popupPosition.positionY}px`,
                left: `${popupPosition.positionX}px`,
                zoom: `${posZoomLevel}%`
            }}
        >
            <Flex>
                <PosFooterButton mr={4} onClick={onOpenIngoingCashChangeModal}>
                    Växel
                </PosFooterButton>
                <PosFooterButton onClick={handleOpenDrawer}>Öppna kassalåda</PosFooterButton>
            </Flex>
        </Popup>
    );
};

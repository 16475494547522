import React from "react";
import { sortBy } from "lodash";

import { usePos } from "Providers";
import { OrderWindow as _OrderWindow } from "Types";
import { CartContent, PosCartFooter } from "./cart";
import { PosCart } from "../../shared/components";
import { useOrderWindowsStore, usePosStore } from "Stores";

type Props = {
    orderWindow: _OrderWindow;
    activePane: number;
};

export const OrderWindow: React.FC<Props> = ({ orderWindow, activePane }) => {
    const {
        editProduct,
        addOrderProductToCart,
        menus,
        updateOrderWindowPuckNo,
        cartUtils: { addOrderProductComment }
    } = usePos();

    const { handleQueueOrders } = usePosStore();

    const { CartManager, incrementOrderProduct, decrementOrderProduct, removeCartProduct } = useOrderWindowsStore();

    const { handleUpdateCartProduct } = CartManager;

    const sortedMenus = sortBy(menus, "sortOrder");

    return (
        <PosCart
            content={
                <CartContent
                    addOrderProductComment={addOrderProductComment}
                    addOrderProductToCart={addOrderProductToCart}
                    handleUpdateCartProduct={handleUpdateCartProduct}
                    increment={incrementOrderProduct}
                    decrement={decrementOrderProduct}
                    removeCartProduct={removeCartProduct}
                    editProduct={editProduct}
                    cartProducts={orderWindow?.cartProducts}
                />
            }
            footer={
                <PosCartFooter
                    activePane={activePane}
                    menus={sortedMenus}
                    addOrderProductToCart={addOrderProductToCart}
                    updateOrderWindowPuckNo={updateOrderWindowPuckNo}
                />
            }
        />
    );
};

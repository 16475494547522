import React from "react";

import { PseudoBox, Box, Button } from "Atoms";
import { APPLIED_DISCOUNT_TYPE, Discount } from "Types";

type Props = {
    disabled: boolean;
    noProductsOnThisDiscount: boolean;
    discount: Discount;
    setDiscount: (discount: Discount | null) => void;
    selectedDiscount: Discount | null;
};

const strikeThroughBox: any = (contentText: string) => {
    return {
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        _before: {
            content: JSON.stringify(contentText),
            display: "block",
            color: "red.500",
            textAlign: "center",
            wordWrap: "break-word",
            padding: "0.5rem",
            position: "absolute",
            fontWeight: "bold",
            zIndex: "1"
        }
    };
};

export const DiscountButton: React.FC<Props> = ({
    disabled,
    noProductsOnThisDiscount,
    discount,
    setDiscount,
    selectedDiscount
}) => {
    const text = discount.amount !== null ? `Belopp: ${discount.amount}kr` : `Procentsats: ${discount.rate}%`;

    const isSelected = selectedDiscount && selectedDiscount.id === discount.id;

    const noProductsMatchText = "Inga matchande produkter";
    const noMatchingProducts = noProductsOnThisDiscount ? strikeThroughBox(noProductsMatchText) : {};
    const nothingMatches = noProductsOnThisDiscount ? { opacity: "0.4" } : { opacity: "1" };
    let appliedDiscountTypeText;

    if (discount.appliedDiscountType) {
        appliedDiscountTypeText =
            discount.appliedDiscountType === APPLIED_DISCOUNT_TYPE.TOTAL ? "(total)" : "(per produkt)";
    }

    return (
        <PseudoBox {...noMatchingProducts}>
            <Button
                disabled={disabled}
                flexDirection="column"
                justifyContent="flex-start"
                style={{ height: "130px", width: "100%" }}
                onClick={() => setDiscount(isSelected ? null : discount)}
                themeColor={isSelected ? "green" : "gray"}
            >
                <Box as="h3" {...nothingMatches} paddingTop={5}>
                    {discount.name}
                </Box>
                <Box as="h4" {...nothingMatches} padding="0" margin="0">
                    {text}
                </Box>
                <Box as="h4" {...nothingMatches} padding="0" margin="0" paddingTop={2}>
                    {appliedDiscountTypeText}
                </Box>
            </Button>
        </PseudoBox>
    );
};

import React from "react";
import { AiFillPlusSquare } from "@react-icons/all-files/ai/AiFillPlusSquare";
import { AiFillMinusSquare } from "@react-icons/all-files/ai/AiFillMinusSquare";

import { Box, Flex } from "Atoms";

type Props = {
    id: string;
    quantity: number;
    discountedQuantity: number;
    productSelected: boolean;
    isDisabled: boolean;
    setDiscountedQuantity: (cartId: string, quantity: number) => void;
};

export const DiscountQuantity: React.FC<Props> = ({
    id,
    quantity,
    discountedQuantity,
    productSelected,
    isDisabled,
    setDiscountedQuantity
}) => {
    const disableSelection = quantity === 1 ? true : isDisabled;
    let colour = isDisabled ? "gray.500" : "orange.500";
    colour = !isDisabled && productSelected && !disableSelection ? "orange.500" : "gray.500";

    const incrementQuantityDiscounted = () => {
        if (discountedQuantity != quantity) {
            setDiscountedQuantity(id, (discountedQuantity += 1));
        }
    };

    const decrementQuantityDiscounted = () => {
        if (discountedQuantity !== 1) {
            setDiscountedQuantity(id, (discountedQuantity -= 1));
        }
    };

    return (
        <Flex
            color={colour}
            cursor={disableSelection || !productSelected ? "not-allowed" : "pointer"}
            onClick={(e: React.MouseEvent<any, MouseEvent>) => {
                e.stopPropagation();
            }}
        >
            <Box
                as={AiFillMinusSquare}
                size="2.5rem"
                width="2.5rem"
                onClick={() => productSelected && decrementQuantityDiscounted()}
            />
            <Box as="h4" pr="0.4rem" pl="0.4rem" mt={2}>
                {discountedQuantity}
            </Box>
            <Box
                as={AiFillPlusSquare}
                size="2.5rem"
                width="2.5rem"
                onClick={() => productSelected && incrementQuantityDiscounted()}
            />
        </Flex>
    );
};

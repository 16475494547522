import React, { PropsWithChildren } from "react";
import { FaUndo } from "@react-icons/all-files/fa/FaUndo";

import { Box, Checkbox, Flex, NewGrid as Grid, Header, NewList, NewListItem, Text } from "Atoms";
import { formatSwedishFinancialNumbers } from "TempUtils";
import { IconButton } from "Molecules";
import { PosCartModifications } from "../cart";
import { CartProductDiscounts } from "./DiscountBody";
import { Discount, OrderWindow } from "Types";
import { AddonsCartItem } from "../../../shared/components";
import { DiscountQuantity } from "./DiscountQuantity";
import { BundleItemList } from "./BundleItemList";
import { isVariablePriceType } from "ProductUtils";
import { useOrderWindowsStore } from "Stores";

export enum Placement {
    DISCOUNT = "DISCOUNT",
    PRICE = "PRICE"
}

type Props = {
    activeOrderWindow: OrderWindow;
    productsToDiscount: Map<string, boolean>;
    validCartProductDiscounts?: CartProductDiscounts[];
    selectedDiscount?: Discount | null;
    updateProductsToDiscount: (id: string) => void;
    resetUnitPrice?: (id: string) => void;
    placement: Placement;
    quantitiesToDiscount?: Map<string, number>;
    setCartQuantityToBeDiscounted?: (cartId: string, quantity: number) => void;
    turnOffComboDiscount?: boolean;
};

const ProductListWrapper: React.FC<{ isDiscountBody: boolean } & PropsWithChildren> = ({
    isDiscountBody,
    children
}) => {
    if (isDiscountBody) {
        return (
            <Grid templateColumns="1fr auto" height="auto" style={{ justifyContent: "flex-start" }}>
                {children}
            </Grid>
        );
    } else {
        return <>{children}</>;
    }
};

export const DiscountProductList: React.FC<Props> = ({
    activeOrderWindow,
    productsToDiscount,
    validCartProductDiscounts,
    selectedDiscount,
    updateProductsToDiscount,
    resetUnitPrice,
    placement,
    quantitiesToDiscount,
    setCartQuantityToBeDiscounted,
    turnOffComboDiscount = false
}) => {
    const { alcoholProductIds } = useOrderWindowsStore();
    const { comboDiscountedProductIds } = activeOrderWindow;
    let productsSelectedToDiscount = productsToDiscount;

    const productList = !!activeOrderWindow?.cartProducts?.length ? (
        activeOrderWindow?.cartProducts?.map(({ id, orderProduct, updatedUnitPrice }) => {
            const { modifications, selectedBundleProductItems } = orderProduct;
            const refId = orderProduct?.refProductId || orderProduct?.refBundleProductId!;

            const isDiscountBody = placement === Placement.DISCOUNT;
            let selectedProduct = !!productsSelectedToDiscount.get(id);
            const isComboDiscount = !!comboDiscountedProductIds?.includes(id);
            const isWeightedProduct = isVariablePriceType(orderProduct?.priceType);
            const isAlcoholProduct = alcoholProductIds.includes(refId);
            const showNoDiscountText = (orderProduct.isOpenProduct || isAlcoholProduct) && isDiscountBody;

            if (selectedProduct && isComboDiscount) {
                productsSelectedToDiscount.delete(id);
                selectedProduct = false;
            }

            let disableSelectProduct =
                (!resetUnitPrice && updatedUnitPrice) ||
                (!!resetUnitPrice &&
                    activeOrderWindow.discountedProductIds &&
                    activeOrderWindow.discountedProductIds.includes(id));

            let hasNoDiscounts: boolean = false;
            if (isDiscountBody) {
                hasNoDiscounts =
                    validCartProductDiscounts?.some(
                        (cart: CartProductDiscounts) => cart.cartProductId === id && !cart.discountIds.length
                    ) ?? false;

                const productIdNotForDiscount =
                    selectedDiscount &&
                    validCartProductDiscounts?.some(
                        (cart: CartProductDiscounts) =>
                            cart.cartProductId === id && !cart.discountIds.includes(selectedDiscount?.id)
                    );
                if (!disableSelectProduct) {
                    disableSelectProduct = hasNoDiscounts;
                }
                if (productIdNotForDiscount) {
                    disableSelectProduct = true;
                }
                if (isAlcoholProduct) {
                    disableSelectProduct = true;
                }
            }

            if (isComboDiscount) {
                disableSelectProduct = true;
            }

            if (orderProduct.isOpenProduct) {
                disableSelectProduct = true;
            }

            const allowQuantityDiscounts = isDiscountBody;
            const quantityInidicator = isWeightedProduct ? 1 : orderProduct.quantity;

            return (
                <ProductListWrapper isDiscountBody={isDiscountBody} key={id}>
                    <NewListItem
                        rounded={10}
                        key={id}
                        padding={2}
                        marginBottom="0.5rem"
                        backgroundColor={selectedProduct && !disableSelectProduct ? "gray.300" : ""}
                        onClick={e => {
                            e.preventDefault();
                            if (!disableSelectProduct) {
                                updateProductsToDiscount(id);
                            }
                        }}
                    >
                        <Flex direction="column">
                            <Grid
                                templateColumns={isDiscountBody ? "repeat(3, 1fr)" : "repeat(2,1fr)"}
                                height="auto"
                                alignItems="center"
                            >
                                <Box>
                                    <Checkbox
                                        disabled={disableSelectProduct}
                                        checked={!disableSelectProduct && !!productsSelectedToDiscount.get(id)}
                                        onChange={() => {}}
                                        name={"productToAdjust"}
                                    >
                                        <Header as="h3" size="lg" mb={orderProduct.modifications ? "0" : "15px"}>
                                            {orderProduct.name}
                                        </Header>
                                    </Checkbox>
                                </Box>
                                <Box justifySelf="flex-end">
                                    <Header
                                        as="h3"
                                        size="lg"
                                        fontWeight="normal"
                                        style={{ userSelect: "none" }}
                                        opacity={selectedProduct ? "0.5" : "1"}
                                        display="flex"
                                        flexDirection="row"
                                    >
                                        {orderProduct.quantity} st |{" "}
                                        {formatSwedishFinancialNumbers(orderProduct.totalPrice)}
                                        {resetUnitPrice && updatedUnitPrice && (
                                            <IconButton
                                                icon={FaUndo}
                                                themeColor="orange"
                                                ml={4}
                                                onClick={(e: any) => {
                                                    e.stopPropagation();
                                                    resetUnitPrice(id);
                                                }}
                                            />
                                        )}
                                    </Header>
                                </Box>
                                {allowQuantityDiscounts && setCartQuantityToBeDiscounted && (
                                    <Box justifySelf="flex-end">
                                        <DiscountQuantity
                                            id={id}
                                            quantity={quantityInidicator}
                                            discountedQuantity={quantitiesToDiscount?.get(id) ?? quantityInidicator}
                                            setDiscountedQuantity={setCartQuantityToBeDiscounted}
                                            productSelected={selectedProduct}
                                            isDisabled={disableSelectProduct}
                                        />
                                    </Box>
                                )}
                            </Grid>
                            <AddonsCartItem addons={orderProduct.addons} ml={4} />
                            {modifications && (
                                <PosCartModifications
                                    modifications={modifications as any}
                                    className="adjust-price-mod"
                                />
                            )}
                            {!!selectedBundleProductItems?.length && (
                                <BundleItemList selectedBundleItems={selectedBundleProductItems} ml={8} />
                            )}
                            {hasNoDiscounts && !isComboDiscount && (
                                <Text color="orange.500" ml={8}>
                                    * Inga matchande rabatter
                                </Text>
                            )}
                            {isComboDiscount && !turnOffComboDiscount && (
                                <Text color="orange.500" ml={8}>
                                    * Komborabatter
                                </Text>
                            )}
                            {showNoDiscountText && (
                                <Text color="orange.500" ml={8}>
                                    * Inga rabatter
                                </Text>
                            )}
                        </Flex>
                    </NewListItem>
                </ProductListWrapper>
            );
        })
    ) : (
        <Flex justifyContent="center">
            <Header as="h3" size="lg" fontWeight="normal">
                --- Tomt ---
            </Header>
        </Flex>
    );
    return <NewList listStyleType="none">{productList}</NewList>;
};

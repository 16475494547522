import React from "react";

import { Text, BaseBoxProps } from "Atoms";
import { SelectedBundleProductItem, Addon } from "Types";
import { AddonsCartItem } from "../../../shared/components";

type BundleProps = {
    selectedBundleItems: SelectedBundleProductItem[];
};

type BundleText = {
    itemList: string[];
    addonList: Addon[];
};

type Props = BundleProps & BaseBoxProps;

const getBundleItemText = (bundleItem: any): string => {
    let sizeName,
        flavourName = "";
    if (bundleItem.modifications) {
        sizeName = bundleItem.modifications.sizes ? bundleItem.modifications.sizes.name : "";
        flavourName = bundleItem.modifications.flavours ? bundleItem.modifications.flavours.name : "";
    }
    return bundleItem.modifications ? `${sizeName} ${flavourName} ${bundleItem.name}`.trim() : bundleItem.name;
};

export const BundleItemList: React.FC<Props> = ({ selectedBundleItems, ...rest }) => {
    const bundleList: BundleText = selectedBundleItems.reduce(
        (bundleText: BundleText, bundleItem: SelectedBundleProductItem) => {
            const text = getBundleItemText(bundleItem);
            if (bundleText.itemList) {
                bundleText.itemList = [...bundleText.itemList, text];
            } else {
                bundleText.itemList = [text];
            }

            if (bundleItem.addons.length) {
                if (bundleText.addonList) {
                    bundleText.addonList = [...bundleText.addonList, ...bundleItem.addons];
                } else {
                    bundleText.addonList = bundleItem.addons;
                }
            }

            return bundleText;
        },
        {} as BundleText
    );

    return (
        <>
            <Text {...rest}>{` - ${bundleList?.itemList?.join(" , ")}`}</Text>
            {bundleList?.addonList && <AddonsCartItem addons={bundleList.addonList} ml={4} />}
        </>
    );
};

import React, { memo } from "react";
import { FiPlus } from "@react-icons/all-files/fi/FiPlus";
import { SiAirtable } from "@react-icons/all-files/si/SiAirtable";

import { Tabs, TabList, Tab, TabPanels } from "Organisms";
import { Header, Center, Box, Button } from "Atoms";
import { IconButton } from "Molecules";
import { OrderWindow } from "Types";
import { getVisibleOrderWindows, useSplitCashRegister, useTheme, useLocalPosSettings } from "Providers";
import { generateOrderWindowsPanes } from "../utils/generatePanesHelpers";
import { useOrderWindowsStore, usePosStore, useTableStore } from "Stores";

const findOrderWindowIndexById = (activeOrderWindowId: string | null, orderWindows: OrderWindow[]) => {
    const activeIndex = orderWindows.findIndex(orderWindow => orderWindow.id === activeOrderWindowId);
    return activeIndex === -1 ? 0 : activeIndex;
};

type Props = {
    posTerminals: any[];
    activePane: string;
    orderWindowsToMove: OrderWindow[];
    cancelMoveTable: () => void;
    lastPurchaseCheck: () => void;
};

type OrderWindowPane = {
    tab: React.ReactNode;
    tabPanel: React.ReactNode;
};

export const OrderWindowTabs: React.FC<Props> = memo(
    ({ posTerminals, activePane, orderWindowsToMove, cancelMoveTable, lastPurchaseCheck }) => {
        const { selectedPos, queueHasStarted } = usePosStore();
        const { orderWindows, addOrderWindow, getActiveOrderWindow, activeOrderWindowId, setActiveOrderWindowId } =
            useOrderWindowsStore();
        const { isInTableSelectionMode, selectedTable } = useTableStore();
        const { isInSplitMode, splitOrderIds } = useSplitCashRegister();
        const { colors } = useTheme();
        const { disableOnLockingPos: isCustomerFacingEnabled } = useLocalPosSettings();

        const visibleOrderWindows = getVisibleOrderWindows(orderWindows, selectedTable?.id, splitOrderIds);
        const onOrderWindowTabChange = (index: number) => {
            const activeIndex = Number(index ?? 0);
            if (!(activeIndex >= orderWindows.length)) {
                const id = visibleOrderWindows[activeIndex].id;
                setActiveOrderWindowId(id);
            }
        };

        // TODO: Not entirely sure what to render here, but there is a race condition when adding a new window for a table (that has never had one)
        //  and setting the activeOrderWindowId in the state.   If we don't have an activeOrderWindow, we should definite not attempt to render,though.
        if (!getActiveOrderWindow()) {
            return <></>;
        }

        // This function contains a hook usage, be careful.
        const orderWindowPanes: OrderWindowPane[] = generateOrderWindowsPanes(
            visibleOrderWindows,
            posTerminals,
            activePane,
            lastPurchaseCheck
        );
        const shouldShowAddOrderWindowButton = !(isInSplitMode || isInTableSelectionMode) && !isCustomerFacingEnabled;
        const isTakeAway = selectedPos?.preferTakeAway || false;

        // TODO: Not entirely sure what to render here, but there is a race condition when adding a new window for a table (that has never had one)
        //  and setting the activeOrderWindowId in the state.   If we don't have an activeOrderWindow, we should definite not attempt to render,though.
        if (!getActiveOrderWindow()) {
            return <></>;
        }

        if (isInTableSelectionMode && !selectedTable) {
            const headerText = !!orderWindowsToMove.length ? "Välj ett ledigt bord för att flytta" : "Välj ett bord";
            return (
                <Center direction="column">
                    <Box as={SiAirtable} size="60px" color="gray.500" />
                    <Header mt={2} color="gray.900" textAlign={"center"}>
                        {headerText}
                    </Header>
                    {!!orderWindowsToMove.length && (
                        <Button themeColor="red" onClick={cancelMoveTable} p={6} fontSize="lg">
                            Avbryt flyttläget
                        </Button>
                    )}
                </Center>
            );
        }

        const activeOrderWindowIndex = findOrderWindowIndexById(activeOrderWindowId, visibleOrderWindows);

        return (
            <Tabs
                index={activeOrderWindowIndex}
                onChange={(index: number) => onOrderWindowTabChange(index)}
                h="100%"
                overflow="hidden"
                display="flex"
                flexDirection="column"
                size="lg"
                isLazy
            >
                {selectedTable && (
                    <Center
                        p={4}
                        backgroundColor={colors.green["200"]}
                        fontSize="2xl"
                        h="auto"
                        color={colors.gray["700"]}
                    >
                        Bord {selectedTable.name}
                    </Center>
                )}
                {queueHasStarted && (
                    <Center
                        p={3}
                        backgroundColor={colors.green["200"]}
                        fontSize="2xl"
                        h="auto"
                        color={colors.gray["700"]}
                    >
                        Orderkö aktiverad
                    </Center>
                )}
                <TabList h="auto" wrap="wrap">
                    {orderWindowPanes.map(orderWindowPane => orderWindowPane.tab)}
                    {shouldShowAddOrderWindowButton && (
                        <Tab as="div" h="auto" p={0}>
                            <IconButton
                                icon={FiPlus}
                                ml={4}
                                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                    e.stopPropagation();
                                    if (selectedTable && selectedTable.id) {
                                        addOrderWindow(null, false, selectedTable.id);
                                    } else {
                                        addOrderWindow(null, isTakeAway, null);
                                    }
                                }}
                            />
                        </Tab>
                    )}
                </TabList>
                <TabPanels flex="1" overflow="auto">
                    {orderWindowPanes.map(orderWindowPane => orderWindowPane.tabPanel)}
                </TabPanels>
            </Tabs>
        );
    }
);

import React, { useState, useEffect } from "react";
import Select, { ValueType } from "react-select";

import { Box, Button, Flex, FormLabel, Header, Keypad, KeypadButton, KeypadButtons, KeypadInput } from "Atoms";
import { Modal, ModalBody, ModalCloseBtn, ModalHeader, SelectOption } from "Molecules";
import { usePos, useTheme } from "Providers";
import { useEventCallback } from "Hooks";
import {
    GOOGLE_ANALYTICS_EVENT_ACTION,
    GOOGLE_ANALYTICS_EVENT_CATEGORY,
    Modification,
    OPEN_PRODUCT_CATEGORIES
} from "Types";
import { gaProductionEvent } from "Utils";

type Props = {
    standardVatRate?: number;
    vatRates: number[];
    closeModal: () => void;
};

const adjustButtons = [
    { value: 1, children: 1 },
    { value: 2, children: 2 },
    { value: 3, children: 3 },
    { value: 4, children: 4 },
    { value: 5, children: 5 },
    { value: 6, children: 6 },
    { value: 7, children: 7 },
    { value: 8, children: 8 },
    { value: 9, children: 9 },
    { value: "Del", children: "Del", themeColor: "red" },
    { value: 0, children: 0 },
    { value: "addPrice", children: "Lägg till", themeColor: "green" }
];

const openProductModifications: Record<OPEN_PRODUCT_CATEGORIES, Modification> = {
    PRODUCT: { name: "Produkt", price: 0, addonPrice: 0 },
    FOOD_DRINK: { name: "Mat & Dryck", price: 0, addonPrice: 0 },
    OTHER: { name: "Övrigt", price: 0, addonPrice: 0 }
};

const modificationVatRates: Record<OPEN_PRODUCT_CATEGORIES, number> = {
    PRODUCT: 25,
    FOOD_DRINK: 12,
    OTHER: 12
};

export const OpenProductModal: React.FC<Props> = ({ standardVatRate, vatRates, closeModal }) => {
    const { colors } = useTheme();
    const { addOpenProductToCart } = usePos();
    const [price, setPrice] = useState<string>("");
    const [vatSelection, setVatSelection] = useState<SelectOption | null>();
    const [productModification, setProductModification] = useState<OPEN_PRODUCT_CATEGORIES | null>(null);

    const getVatSelectOption = (value: number): SelectOption => {
        return {
            label: "" + value + "%",
            value: "" + value
        };
    };

    const onSetProductModification = (type: OPEN_PRODUCT_CATEGORIES) => {
        const vatRate = modificationVatRates[type];
        setProductModification(type);
        setVatSelection(getVatSelectOption(vatRate));
    };

    /**
     * Function on load here checks the type sent in, checks if it is the standard vat rate
     * if it is the same rate as the type it will choose that
     * if for some reason the standard vat rate is different it will check others and if not
     * chooses OTHER selection with the standard vat rate passed in
     * @param {OPEN_PRODUCT_CATEGORIES} type
     */
    const onInitialSetProductModification = (type: OPEN_PRODUCT_CATEGORIES) => {
        const vatRate = modificationVatRates[type];
        const isStandardVat = vatRate === standardVatRate;
        if (isStandardVat || !standardVatRate) {
            setProductModification(type);
            setVatSelection(getVatSelectOption(vatRate));
        } else {
            let standardProductModification: OPEN_PRODUCT_CATEGORIES;
            if (modificationVatRates.PRODUCT === standardVatRate) {
                standardProductModification = OPEN_PRODUCT_CATEGORIES.PRODUCT;
                setVatSelection(getVatSelectOption(modificationVatRates[standardProductModification]));
            } else {
                standardProductModification = OPEN_PRODUCT_CATEGORIES.OTHER;
                setVatSelection(getVatSelectOption(standardVatRate));
            }
            setProductModification(standardProductModification);
        }
    };

    const vatSelectOptions = vatRates.map((value: number) => {
        return getVatSelectOption(value);
    });

    const onChangeVatSelection = (select: ValueType<SelectOption, false>) => {
        setVatSelection(select as SelectOption);
    };

    const onAddPriceAndCloseModal = () => {
        const totalPrice = Number(price);
        if (totalPrice > 0 && productModification) {
            addOpenProductToCart(
                Number(price),
                Number(vatSelection?.value),
                openProductModifications[productModification],
                productModification
            );
        }
        gaProductionEvent({
            category: GOOGLE_ANALYTICS_EVENT_CATEGORY.POS_FOOTER,
            action: GOOGLE_ANALYTICS_EVENT_ACTION.ADD_TO_CART,
            label: "Add open product"
        });
        closeModal();
    };

    const deletePrice = () => {
        setPrice(prev => prev.slice(0, -1));
    };

    /**Setting defaults on open */
    useEffect(() => {
        onInitialSetProductModification(OPEN_PRODUCT_CATEGORIES.FOOD_DRINK);
    }, []);

    useEventCallback({
        eventName: "keydown",
        callback: (e: KeyboardEvent) => {
            if (e.key === "Escape") {
                closeModal();
            }

            if (e.key === "Enter") {
                e.preventDefault();
                onAddPriceAndCloseModal();
            }
        }
    });

    const showVatRates = productModification === OPEN_PRODUCT_CATEGORIES.OTHER;
    const isFoodAndDrink = productModification === OPEN_PRODUCT_CATEGORIES.FOOD_DRINK;
    const isProduct = productModification === OPEN_PRODUCT_CATEGORIES.PRODUCT;
    const isOther = productModification === OPEN_PRODUCT_CATEGORIES.OTHER;

    return (
        <Modal open onClose={closeModal} size="xs" placement="center">
            <ModalCloseBtn
                onClick={closeModal}
                size="lg"
                style={{ backgroundColor: colors.red["500"], color: colors.white, borderRadius: "50%" }}
            />
            <ModalHeader>
                <Header as="h3" size="lg" m="0">
                    Öppen Produkt
                </Header>
            </ModalHeader>
            <ModalBody>
                <Flex mb={6}>
                    <Button
                        themeColor={isFoodAndDrink ? "green" : "gray"}
                        height="7rem"
                        mr={2}
                        flex="1"
                        display="flex"
                        flexDirection="column"
                        onClick={() => onSetProductModification(OPEN_PRODUCT_CATEGORIES.FOOD_DRINK)}
                    >
                        <Box>{`Mat & Dryck`}</Box>
                        <Box mt={3}>{`${modificationVatRates[OPEN_PRODUCT_CATEGORIES.FOOD_DRINK]} % moms`}</Box>
                    </Button>
                    <Button
                        themeColor={isProduct ? "green" : "gray"}
                        height="7rem"
                        mr={2}
                        flex="1"
                        display="flex"
                        flexDirection="column"
                        onClick={() => onSetProductModification(OPEN_PRODUCT_CATEGORIES.PRODUCT)}
                    >
                        <Box>Produkt</Box>
                        <Box mt={3}>{`${modificationVatRates[OPEN_PRODUCT_CATEGORIES.PRODUCT]} % moms`}</Box>
                    </Button>
                    <Button
                        themeColor={isOther ? "green" : "gray"}
                        onClick={() => onSetProductModification(OPEN_PRODUCT_CATEGORIES.OTHER)}
                        height="7rem"
                        flex="1"
                    >
                        Övrigt
                    </Button>
                </Flex>

                {showVatRates && (
                    <Flex flexDirection="column" mb={6}>
                        <FormLabel>Moms</FormLabel>
                        {/** @ts-ignore */}
                        <Select
                            value={vatSelection}
                            options={vatSelectOptions}
                            onChange={onChangeVatSelection}
                        ></Select>
                    </Flex>
                )}
                <Keypad
                    isDefaultOpen
                    isOpen
                    customHandlers={{ addPrice: onAddPriceAndCloseModal, Del: () => deletePrice() }}
                    onChange={(value: any) => setPrice(value)}
                    value={price ?? 0}
                >
                    <KeypadInput fullWidth size="xl" placeholder="Pris inkl moms" mb={2} />
                    <KeypadButtons>
                        {adjustButtons.map(button => (
                            <KeypadButton key={button.value} {...button} />
                        ))}
                    </KeypadButtons>
                </Keypad>
            </ModalBody>
        </Modal>
    );
};

import React, { useState } from "react";

import { Keypad, KeypadButton, KeypadButtons, Button, Header, Box } from "Atoms";
import { Modal, ModalHeader, ModalBody } from "Molecules";
import { useOrderWindowsStore, usePosStore } from "Stores";
import { StringOrNumber } from "Types";
import { cancelOrderByCashier } from "../utils";

export const cashChangeButtons = [
    { value: 1, children: 1 },
    { value: 2, children: 2 },
    { value: 5, children: 5 },
    { value: 10, children: 10 },
    { value: 20, children: 20 },
    { value: 50, children: 50 },
    { value: 100, children: 100 },
    { value: 200, children: 200 },
    { value: 500, children: 500 },
    { value: "Rensa", children: "Rensa", themeColor: "red" },
    { value: 1000, children: 1000 },
    { value: "Klar", children: "Klar", themeColor: "green" }
];

const initialState = {
    withdrawnAmount: 0,
    changeAmount: 0
};

type Props = {
    modalContent: any;
    onSubmit: (valueObject: any) => void;
};

export const CalculateCashChangeModal: React.FC<Props> = ({ modalContent, onSubmit }) => {
    const [amounts, setAmounts] = useState(initialState);
    const { amount: amountToPay } = modalContent.paymentMethodAmount; // might break this out

    const { activeOrderWindowId } = useOrderWindowsStore();
    const { updateActivePaymentTabStatus } = usePosStore();

    const handleSetAmounts = (value: number) => {
        setAmounts(prevAmounts => {
            const widthDrawnSum = value + prevAmounts.withdrawnAmount;
            const changeAmountSum = Math.abs(widthDrawnSum) > amountToPay ? widthDrawnSum - Math.round(amountToPay) : 0;
            return {
                withdrawnAmount: widthDrawnSum,
                changeAmount: changeAmountSum
            };
        });
    };

    const resetAmount = () => setAmounts(initialState);

    const onCloseModal = () => onSubmit({ value: null, cancel: true });

    const leftToPay =
        amounts.withdrawnAmount === 0 ? Math.round(amountToPay) : Math.round(amountToPay) - amounts.withdrawnAmount;
    const disabledKeypadButtons = amounts.withdrawnAmount < Math.round(amountToPay) ? ["Klar"] : [];

    return (
        <Modal open onClose={onCloseModal}>
            <ModalHeader display="flex" justifyContent="flex-end" alignContent="center">
                <Button
                    themeColor="red"
                    size="lg"
                    onClick={() => {
                        cancelOrderByCashier(updateActivePaymentTabStatus, activeOrderWindowId);
                        onCloseModal();
                    }}
                >
                    Avbryt
                </Button>
            </ModalHeader>
            <ModalBody>
                <Box mb={8}>
                    <Header>Kvar att betala: {amounts.withdrawnAmount >= amountToPay ? "0" : leftToPay} kr</Header>
                    <Header>Växel tillbaka: {amounts.changeAmount} kr</Header>
                </Box>
                <Keypad
                    isOpen
                    shouldSumValue={false}
                    value={amounts.changeAmount}
                    onChange={(value: StringOrNumber) => handleSetAmounts(Number(value))}
                    customHandlers={{
                        Rensa: resetAmount,
                        Klar: () => onSubmit({ value: amounts, cancel: false })
                    }}
                    disabledButtons={disabledKeypadButtons}
                    w="100%"
                >
                    <KeypadButtons>
                        {cashChangeButtons.map(button => (
                            <KeypadButton key={button.value} {...button} />
                        ))}
                    </KeypadButtons>
                </Keypad>
            </ModalBody>
        </Modal>
    );
};

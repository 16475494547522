import React, { useState, useEffect } from "react";

import { Modal, ModalHeader, ModalBody, Dimmer, DimmerContent, RadioButtonGroup } from "Molecules";
import { Header, Button, Box, Flex, Icon, Text, CustomRadioButton, FormLabel } from "Atoms";
import { PaymentMethod, BaxiTerminalAction } from "Constants";
import { Settings, SelectedTerminal, PreferredPuckTab } from "Types";
import { useBaxiSocket, useQopla, usePos, useLocalPosSettings, useLanguage } from "Providers";
import { usePosStore } from "Stores";
import { confirmOrCancelNotification } from "TempUtils";

type Props = {
    posTerminals: SelectedTerminal[];
    handleCreateXReport: () => void;
    onOpenAllergyModal: () => void;
    onOpenPunchClock: () => void;
    closeModal: () => void;
    enablePunchClock: boolean;
    shopSettings: Settings;
    onOpenCodeCheckModal: () => void;
    postponePaymentEnabled: boolean;
};

const OngoingReconciliation: React.FC<{ dimmerMessage: string }> = ({ dimmerMessage }) => (
    <Flex
        justifyContent="center"
        alignItems="center"
        height="100%"
        width="100%"
        zIndex={9999}
        position="absolute"
        top="0"
        right="0"
        direction="column"
    >
        <Header as="h3" size="lg">
            Dagsavslut pågår
        </Header>
        <Text whiteSpace="pre" textAlign="center" fontSize="lg">
            {dimmerMessage}
        </Text>
    </Flex>
);

export const PosMoreAndSettingsModal: React.FC<Props> = ({
    handleCreateXReport,
    onOpenAllergyModal,
    onOpenPunchClock,
    enablePunchClock,
    closeModal,
    shopSettings,
    posTerminals,
    onOpenCodeCheckModal,
    postponePaymentEnabled
}) => {
    const { posZoomLevel, updatePosZoomLevel } = usePos();
    const { queueHasStarted, handleQueueOrders } = usePosStore();
    const { selectedPos } = useQopla();
    const { attemptReconciliation, terminalMessages, terminalStates } = useBaxiSocket();
    const { translate } = useLanguage();

    const [dimmerMessage, setDimmerMessage] = useState<string>("");

    const {
        posSettings: settingOptions,
        setLocalPosSettings,
        setPosSettings: {
            setEnableManualCardPayment,
            setEnableOpenProduct,
            setOptionalReceipts,
            setPreferredPuckTab,
            setEnableOrderQueue
        }
    } = useLocalPosSettings();

    const {
        enableManualCardPayment,
        optionalReceipts,
        preferredPuckTab,
        enableOpenProduct,
        customerFacingOptions,
        enableOrderQueue
    } = settingOptions;

    const onCloseModalCallback = (func: Function) => {
        func();
        closeModal();
    };

    const onToggleManualCardPayment = () => {
        setEnableManualCardPayment(!enableManualCardPayment);
        setLocalPosSettings({ ...settingOptions, enableManualCardPayment: !enableManualCardPayment });
    };
    const onToggleOptionalReceipt = () => {
        setOptionalReceipts(!optionalReceipts);
        setLocalPosSettings({ ...settingOptions, optionalReceipts: !optionalReceipts });
    };

    const onSetPreferredPuckTab = (preferredPuckTab: PreferredPuckTab) => {
        setPreferredPuckTab(preferredPuckTab);
        setLocalPosSettings({ ...settingOptions, preferredPuckTab: preferredPuckTab });
    };

    const reduceCardStylePos = () => {
        const decreasedPosZoomLevel = posZoomLevel - 10;
        updatePosZoomLevel(decreasedPosZoomLevel);
    };

    const zoomCardStylePos = () => {
        const increasedPosZoomLevel = posZoomLevel + 10;
        updatePosZoomLevel(increasedPosZoomLevel);
    };

    const onToggleOpenProduct = () => {
        setEnableOpenProduct(!enableOpenProduct);
        setLocalPosSettings({ ...settingOptions, enableOpenProduct: !enableOpenProduct });
    };

    const onToggleOrderQueue = () => {
        if (queueHasStarted) {
            handleQueueOrders();
        }
        setEnableOrderQueue(!enableOrderQueue);
        setLocalPosSettings({ ...settingOptions, enableOrderQueue: !enableOrderQueue });
    };

    useEffect(() => {
        let message = "";
        const noTerminals = posTerminals.reduce((acc, next) => (acc += next.cloudEnabled ? 1 : 0), 0);

        terminalStates?.forEach((_terminalState, terminalId) => {
            if (_terminalState.action === BaxiTerminalAction.ADMIN) {
                const terminal = posTerminals.find(terminal => terminal.terminalId === terminalId);
                const terminalName = noTerminals > 1 ? `${terminal?.name}: ` : "";
                message += `${terminalName}${terminalMessages.get(terminalId) || ""}`;
                message += "\n";
            }
        });
        setDimmerMessage(message);
    }, [terminalMessages]);

    const showManualCard = shopSettings?.allowedPaymentMethods?.includes(PaymentMethod.CARD);

    const buttons = [
        {
            title: "Allergiguide",
            onClick: () => onCloseModalCallback(onOpenAllergyModal),
            show: true
        },
        {
            title: "Skapa & Skriv ut X-Rapport",
            onClick: () => onCloseModalCallback(handleCreateXReport),
            show: true
        },
        {
            title: `${enableManualCardPayment ? "Dölj" : "Visa"} manuella kort`,
            onClick: async () => {
                if (enableManualCardPayment) {
                    onCloseModalCallback(onToggleManualCardPayment);
                } else {
                    const { value } = await confirmOrCancelNotification(
                        `${translate("show")} ${translate("manualCard")} ${translate("button")}?`,
                        `${translate("enableManualCardWarningText")}`,
                        translate("yes"),
                        "#d33",
                        translate("cancel")
                    );
                    if (value) {
                        onCloseModalCallback(onToggleManualCardPayment);
                    }
                }
            },
            show: showManualCard
        },
        {
            title: `${optionalReceipts ? "Avaktivera" : "Aktivera"} kvittoförfrågan`,
            onClick: () => onCloseModalCallback(onToggleOptionalReceipt),
            show: true
        },
        {
            title: "Stämpelklocka",
            onClick: () => onCloseModalCallback(onOpenPunchClock),
            show: enablePunchClock
        },
        {
            title: "Dagsavslut",
            onClick: () => attemptReconciliation(),
            show: posTerminals?.some(terminal => terminal.cloudEnabled)
        },
        {
            title: `${enableOpenProduct ? "Avaktivera" : "Aktivera"} öppen produkt`,
            onClick: () => onCloseModalCallback(onToggleOpenProduct),
            show: true
        },
        {
            title: `Aktivera kundanvändning`,
            onClick: () => onOpenCodeCheckModal(),
            show: !customerFacingOptions.enableCustomerFacing && customerFacingOptions.allowCustomerFacing
        },
        {
            title: `${enableOrderQueue ? "Avaktivera" : "Aktivera"} orderkö`,
            onClick: () => onCloseModalCallback(onToggleOrderQueue),
            show: !postponePaymentEnabled
        }
    ];

    const displayDimmer = !!dimmerMessage;

    return (
        <Modal open onClose={closeModal} size="2xs">
            <ModalHeader display="flex" justifyContent="space-between" alignItems="center">
                <Header as="h3" size="lg" m="0">
                    Mer & Inställningar
                </Header>
                <Button themeColor="red" size="lg" onClick={closeModal}>
                    Stäng
                </Button>
            </ModalHeader>
            <ModalBody>
                {displayDimmer && <OngoingReconciliation dimmerMessage={dimmerMessage} />}
                <Dimmer isActive={displayDimmer}>
                    <DimmerContent bg="whiteAlpha.900" />

                    <Box>
                        <Button mb={6} size="lg" fullWidth>
                            <Flex align="center" justify="space-between" width="100%">
                                <Icon name="FaMinus" size="tiny" onClick={reduceCardStylePos} />
                                <Box fontFamily="sans-serif">Zoom ({posZoomLevel}%)</Box>
                                <Icon name="FaPlus" size="tiny" onClick={zoomCardStylePos} />
                            </Flex>
                        </Button>

                        {buttons.map(
                            ({ title, show, onClick }) =>
                                show && (
                                    <Button mb={6} key={title} size="lg" fullWidth onClick={onClick}>
                                        {title}
                                    </Button>
                                )
                        )}
                        {selectedPos?.puckEnabled && (
                            <Box>
                                <FormLabel>Puck nr. stil</FormLabel>
                                <RadioButtonGroup
                                    rounded="md"
                                    activeThemeColor="blue"
                                    stretch={2}
                                    value={preferredPuckTab}
                                    onValueChanged={updatedPreferredPuckTab =>
                                        onSetPreferredPuckTab(updatedPreferredPuckTab as PreferredPuckTab)
                                    }
                                >
                                    <CustomRadioButton size="lg" flex={1} value={PreferredPuckTab.NUM_PAD}>
                                        Siffra
                                    </CustomRadioButton>
                                    <CustomRadioButton size="lg" flex={1} value={PreferredPuckTab.CUSTOM_INPUT}>
                                        Text
                                    </CustomRadioButton>
                                </RadioButtonGroup>
                            </Box>
                        )}
                    </Box>
                </Dimmer>
            </ModalBody>
        </Modal>
    );
};

import React, { useEffect, useState } from "react";

import { Box, NewGrid as Grid, Keypad, KeypadInput, KeypadButtons, KeypadButton } from "Atoms";
import { DiscountUtils } from "./DiscountPriceModal";
import { GOOGLE_ANALYTICS_EVENT_ACTION, GOOGLE_ANALYTICS_EVENT_CATEGORY, OrderWindow } from "Types";
import { DiscountProductList, Placement } from "./DiscountProductList";
import { gaProductionEvent } from "Utils";

type Props = {
    activeOrderWindow: OrderWindow;
    discountUtils: DiscountUtils;
    isDisplayed: boolean;
};

const adjustButtons = [
    { value: 1, children: 1 },
    { value: 2, children: 2 },
    { value: 3, children: 3 },
    { value: 4, children: 4 },
    { value: 5, children: 5 },
    { value: 6, children: 6 },
    { value: 7, children: 7 },
    { value: 8, children: 8 },
    { value: 9, children: 9 },
    { value: "Del", children: "Del", themeColor: "red" },
    { value: 0, children: 0 },
    { value: "Uppdatera", children: "Uppdatera", themeColor: "green" }
];

export const PriceAdjustBody: React.FC<Props> = ({ activeOrderWindow, discountUtils, isDisplayed }) => {
    const [productsToDiscount, setProductsToDiscount] = useState<Map<string, boolean>>(new Map());
    const [newPrice, setNewPrice] = useState("");
    const { resetUnitPrice, updateUnitPrice } = discountUtils;

    useEffect(() => {
        if (!isDisplayed) {
            setProductsToDiscount(new Map());
        }
    }, [isDisplayed]);

    const updateProductsToDiscount = (id: string) => {
        const checked = productsToDiscount && productsToDiscount.get(id) === true;
        const t = productsToDiscount.set(id, !checked);
        setProductsToDiscount(new Map(t));
    };

    const updateNewUnitPrice = () => {
        const productIds = Array.from(productsToDiscount)
            .filter(([id, value]) => value)
            .map(([id, value]) => id);
        updateUnitPrice(parseInt(newPrice), productIds);
        setNewPrice("");
        gaProductionEvent({
            category: GOOGLE_ANALYTICS_EVENT_CATEGORY.POS_FOOTER,
            action: GOOGLE_ANALYTICS_EVENT_ACTION.CLICK_BUTTON,
            label: "Set price adjustment"
        });
    };

    const disableDiscountButtons = Array.from(productsToDiscount).filter(([_, value]) => value).length === 0;
    let disabledKeypadButtons = disableDiscountButtons ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, "Del", "Uppdatera"] : [];
    if (newPrice === "" && !disableDiscountButtons) disabledKeypadButtons = ["Uppdatera"];

    return (
        <Grid height="500px" p={8} gap={6} templateColumns={{ xs: "1fr", md: "1fr 1fr" }}>
            <Box overflow="auto">
                <DiscountProductList
                    activeOrderWindow={activeOrderWindow}
                    productsToDiscount={productsToDiscount}
                    updateProductsToDiscount={updateProductsToDiscount}
                    resetUnitPrice={resetUnitPrice}
                    placement={Placement.PRICE}
                />
            </Box>

            <Box>
                <Keypad
                    isDefaultOpen
                    isOpen
                    customHandlers={{ Uppdatera: updateNewUnitPrice, Del: () => setNewPrice(newPrice.slice(0, -1)) }}
                    onChange={(value: any) => setNewPrice(String(value))}
                    value={newPrice}
                    disabledButtons={disabledKeypadButtons}
                >
                    <KeypadInput fullWidth size="xl" placeholder="Nytt pris" />
                    <KeypadButtons>
                        {adjustButtons.map(button => (
                            <KeypadButton key={button.value} {...button} />
                        ))}
                    </KeypadButtons>
                </Keypad>
            </Box>
        </Grid>
    );
};

import React, { useEffect, useState } from "react";

import { useInterval } from "Hooks";
import { useQopla } from "Providers";
import { BackendConnectionIndicator, heartBeatMessage } from "TempUtils";
import { useInternalPos } from "../../InternalPosProvider";

type Props = {
    cloudCardTerminalConnected: boolean | undefined;
};

export const ConnectionContainer: React.FC<Props> = ({ cloudCardTerminalConnected }) => {
    const { selectedPos } = useQopla();

    const receiptPrinter = selectedPos ? selectedPos.receiptPrinter : null;

    const { pingResponse } = useInternalPos();

    const [printerIsConnected, setPrinterIsConnected] = useState(false);

    const checkPrinterConnected = async () => {
        if (receiptPrinter) {
            const printerResult = await heartBeatMessage(receiptPrinter.ip, receiptPrinter.deviceName);
            if (printerResult !== printerIsConnected) {
                setPrinterIsConnected(printerResult);
            }
        }
    };

    useEffect(() => {
        checkPrinterConnected();
    }, []);

    useInterval(checkPrinterConnected, 30000);

    return (
        <BackendConnectionIndicator
            isBackendConnected={pingResponse.mothershipAlive}
            isControlUnitConnected={pingResponse.controlUnitAlive}
            isPrinterConnected={printerIsConnected}
            cloudCardTerminalConnected={cloudCardTerminalConnected}
        />
    );
};
